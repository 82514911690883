import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

class TeamIndex extends React.Component {
  render () {
    const people = get(this.props, 'data.people.edges')
    const jobs = get(this.props, 'data.jobs.edges')
    const fiJobs = jobs.filter(post => post.node.showOnSites.includes('fi'))

    return (
      <ContentfulPage
        blocks={this.props.blocks}
        people={people}
        jobs={fiJobs}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Team`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/team`}
        pageDescription='Field is a multinational, interdisciplinary team on a mission in emerging-market healthcare.'
      />
    )
  }
}

export default withContentfulBlocks(TeamIndex)

export const pageQuery = graphql`
  query FITeamQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "3U41j5dnrV2fw8J8VbpRIs" }) {
      ...PageBlocksFragment
    }
    people: allContentfulPerson(filter: { archive: { eq: false } }, sort: { fields: [lastName], order: ASC }) {
      edges {
        node {
          firstName
          lastName
          image {
            fluid(
              quality: 60
              maxWidth: 300
              maxHeight: 300
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          link
        }
      }
    }
    jobs: allContentfulJobPosting(sort: { fields: [title], order: DESC }) {
      edges {
        node {
          title
          category
          teaser {
            childMarkdownRemark {
              html
            }
          }
          slug
          location
          showOnSites
        }
      }
    }
  }
`
